<template>
    <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/yallagame.png" style="height: 50px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;">تسجيل دخول</h5>
              </div>
              <form  @submit.prevent="onLogin()">
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                      <input type="text" v-model="msisdn" class="form-control" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'" style="text-align: center;">
                    </div>
                </div>
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                      <input type="text" v-model="password" class="form-control" autocomplete="off" placeholder="كلمة المرور" onfocus="this.placeholder = ''" onblur="this.placeholder = 'كلمة المرور'" style="text-align: center;">
                    </div>
                </div>
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase">
                <span v-if="loading" class="px-1 arabickufi">جاري تسجيل الدخول</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">تسجيل الدخول</span>
            </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { ref } from 'vue';
export default {
  name: 'AppLogin',
  setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const toast = useToast();
      const msisdn = ref('');
      const password = ref('');
      const loading = ref(false);
      var number;

      const onLogin = async () => {

        const Regex =  new RegExp(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
        const Pass =  new RegExp(/^\d{4}$/gm);
        // const NewPass = password.value;
            if (password.value === '') {
              toast.error("حقل كلمة السر فارغة");
            } else if (!Pass.test(password.value)) {
              toast.error("كلمة السر خاطئة");
              // console.log( password.value )
            }
            if (msisdn.value === '') {
                toast.error("لا يمكن ترك حقل رقم الهاتف فارغًا");
            } else if (!Regex.test(msisdn.value)) {
                toast.error("رقم الهاتف غير صحيح");
            } else {
                if (msisdn.value.substring(0, 1) === "0" && msisdn.value.length === 10) {
                    number = msisdn.value.substring(1);
                } else if (msisdn.value.substring(0, 1) === "5" && msisdn.value.length === 9) {
                    number = msisdn.value.substring();
                } else if (msisdn.value.substring(0, 1) === "9" && msisdn.value.length === 12) {
                    number = msisdn.value.substring(3);
                } else if (msisdn.value.substring(0, 1) === "0" && msisdn.value.length === 14) {
                    number = msisdn.value.substr(5);
                }
                if (number.length === 9) {
                  // console.log( number )
                  loading.value = true;
                  await HTTPSPA.post(`CheckLogin.php?msisdn=966${number}&password=${password.value}`).then((res) => {
                      if (res.data.status == 1 && res.data.responseCode == 1 && res.data.MINTUES > 0 ) {
                          cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("responseCode", res.data.responseCode, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("password", password.value, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("MINTUES", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("showHaeder", res.data.ServiceCode, { expire: 60 * res.data.MINTUES,});
                          toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من منصة يلا قيم");
                          setTimeout(() => router.push({ path: "/" }), 2500);
                      } else if (res.data.status == 1 && res.data.responseCode == 1 && res.data.MINTUES == 0 ) {
                          cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("responseCode", res.data.responseCode, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("password", password.value, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("MINTUES", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("showHaeder", res.data.ServiceCode, { expire: 60 * res.data.MINTUES,});
                          toast.info("عفوا لم يتم تجديد اشتراكك");
                          setTimeout(() => router.push({ path: "/" }), 2500);
                      } else if (res.data.status == 0 && res.data.responseCode == 1 && res.data.MINTUES == 0 ) {
                          cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("responseCode", res.data.responseCode, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("password", password.value, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("MINTUES", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                          cookie.setCookie("showHaeder", res.data.ServiceCode, { expire: 60 * res.data.MINTUES,});
                          toast.info("عفوا لم يتم تجديد اشتراكك");
                          setTimeout(() => router.push({ path: "/" }), 2500);
                      } else if (res.data.status == 0 && res.data.responseCode == 0 && res.data.MINTUES == '' ) {
                          toast.warning("عفوا ليس لديك اشتراك في هذة الخدمة اشترك الان ");
                          setTimeout(() => router.push({ name: "Subscribes" }), 2500);
                      }
                  }).catch((error) => {
                      console.log(error);
                  }).finally(() => {
                      loading.value = false;
                  });

                }
            }
      }
      return {onLogin ,msisdn, password, loading}
  }
  // data() {
  //   return {
  //       msisdn: "",
  //       password: "",
  //       loading: false,
  //   };
  // },
  // mounted() {
  //   if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
  //       this.$router.push({ path: "/" })
  //   }
  // },
  // methods: {
  //   async onLogin() {
      // const toast = useToast();
      // if(this.msisdn.startsWith(9)) {
      //     toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
      // } else {
      // if (this.msisdn != "") {
      //   const str = this.msisdn;
      //   if (str.substr(0, 1) === "0") {
      //     let number = str.substr(1);
      //     if (number.startsWith(1)) {
      //       this.phone = number;
      //     } else {
      //       toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
      //     }
      //   } else if (str.substr(0, 1) === "2" && str.length === 12) {
      //     let number = str.substr(3);
      //     if (number.startsWith(1)) {
      //       this.phone = number;
      //     } else {
      //       toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
      //     }
      //   } else if (str.substr(0, 1) === "1" && str.length === 9) {
      //     let number = str.substr();
      //     if (number.startsWith(1)) {
      //       this.phone = number;
      //     } else {
      //       toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
      //     }
      //   } else if (str.length != 9) {
      //     toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", { timeout: 2500,});
      //   }
      //     if (this.phone.startsWith(1)) {
      //       this.loading = true;
      //       await HTTPSPA.post(`CheckLogin.php?username=249`+this.phone +`&serviceId=2222&operatorID=2`).then((res) => {
      //           if (res.data.status === 1 && res.data.MINTUES > 0) {
      //               this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * res.data.MINTUES, });
      //               this.$cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
      //               this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
      //               toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من منصة لعبتنا", { timeout: 2500 });
      //               setTimeout(() => this.$router.push({ path: "/" }), 2500);
      //           } else if (res.data.status === 1 && res.data.MINTUES < 0) {
      //               toast.success("تم تسجيل الدخول", { timeout: 2500, });
      //               this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
      //               this.$cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
      //               this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
      //           } else if (res.data.status === 0 && res.data.responseCode === 118) {
      //               this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
      //               toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
      //               setTimeout(() => this.$router.push({ path: "/" }), 2500);
      //           }else if (res.data.status === 0) {
      //               toast.warning("عفوا انت لست مشترك في منصة لعبتنا", { timeout: 2500, });
      //               setTimeout(() => this.$router.push({ path: "/" }), 2500);
      //           }else {
      //               toast.info("عفوا انت لست مشترك في خدمة لعبتنا" , { timeout: 2500 });
      //           }
      //         }).catch((error) => {
      //           console.log(error);
      //         }).finally(() => {
      //           this.loading = false;
      //         });
      //     } else {
      //       toast.info("رقم الهاتف ليس سوداني", { timeout: 2500 });
      //     }
      // } else {
      //   toast.info("الرجال ادخال رقم الهاتف", { timeout: 2500 });
      // }
      // }      
    // },
  // },
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: #315b10 none repeat scroll 0% 0% !important
}

.bg-dark {
  background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
}
</style>